import React from "react";
import { Script } from "gatsby";

export const wrapPageElement = ({ element }) => {
	const GDPR = "ababababab";
	const GDPR_CONSENT_755 = "dcdcdcdcd";
	var axel = Math.random() + "";
	var a = axel * 10000000000000;

	return (
		<>
			{element}

			{/* Doubleclick Tracking */}
			<Script id='second-unique-id'>
				{`
          // Doubleclick Tracking
          const iframe = document.createElement("iframe");
          iframe.style.display = 'none';
          iframe.src = "https://8802693.fls.doubleclick.net/activityi;src=8802693;type=pagev0;cat=gv-un0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=${
					a + "?"
				}";
          iframe.height = "1";
          iframe.width = "1";
          iframe.frameborder = "0";
          document.body.appendChild(iframe);
          console.info('Doubleclick script loaded! ✔')
        `}
			</Script>

			{/* Google Tag Manager */}
			<Script id='google-tag-manager'>
				{`
			// Google Tag Manager
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TKHJTVX');
        `}
			</Script>
			<noscript id='google-tag-manager-noscript'>
				{/* Google Tag Manager (noscript) */}
				<iframe
					src='https://www.googletagmanager.com/ns.html?id=GTM-TKHJTVX'
					height='0'
					width='0'
					style={{ display: "none", visibility: "hidden" }}
				></iframe>
			</noscript>
		</>
	);
};
